<template>
    <div class="Map AMap" id="Map">
        <div id="viewDiv" class="viewDiv" @contextmenu="folderMouseControl" @click.self="hidemenu">
            <div class="menu">
                <ul id="MapMouse" style="display: none" :style="{ left: menuX, top: menuY }">
                    <!-- <li @click.stop="clearScreen">
                        <i>
                            <img src="@/assets/mapimages/maptools/dtyj4.png" alt />
                        </i>
                        <span>清屏</span>
                    </li>
                    <li @click.stop="Choiceof">
                        <i>
                            <img src="@/assets/mapimages/maptools/dtyj5.png" alt />
                        </i>
                        <span>选择</span>
                    </li>
                    <li @click.stop="mapMove">
                        <i>
                            <img src="@/assets/mapimages/maptools/dtyj2.png" alt />
                        </i>
                        <span>移动</span>
                    </li>
                    <li @click.stop="goTo">
                        <i>
                            <img src="@/assets/mapimages/maptools/dtyj3.png" alt />
                        </i>
                        <span>全屏</span>
                    </li> -->
                </ul>
            </div>

            <!-- 查询栏 -->
            <div id="titleDiv" class="esri-widget">
                <Search />
            </div>
        </div>

        <!-- 查询结果 -->
        <div class="query_result" v-show="queryShow">
            <div class="nav">
                <div class="left_nav">
                    <span></span>
                    <span>搜索结果({{ findData.length }})</span>
                </div>
                <div class="right_nav" @click="closeQueryResult">
                    <i>
                        <img src="@/assets/mapimages/maptools/popup_close_15d2283.gif" alt />
                    </i>
                </div>
            </div>
            <div class="noresult main_result" v-if="findData.length == 0">
                <h3>无搜索结果</h3>
            </div>
            <!-- =================================================搜索结果 -->
            <div style="height: 100%;width: 100%;" v-else>
                <div class="main_result">
                    <div class="item_result" @click="activeId = item.ID;" v-for="item in findData" :key="item.id" :class="{active:activeId==item.ID}">
                        <div class="left_item">
                            <i @click="location(item)" v-if="item.isShowPosi">
                                <img src="@/assets/mapimages/new/ic_location.png" alt title="图形定位" />
                            </i>
                        </div>
                        <div class="center_item">
                            <span @click="location(item)">{{ item.TXMMC ? item.TXMMC : item.XMMC}}</span>
                            <div style="display: flex;justify-content: space-between;width: 100%;">
                                <span v-if="item.GLLX == '普通国道'" class="leftbg leftbg1">{{ item.GLLX }} </span>
                                <span v-if="item.GLLX == '普通省道'" class="leftbg leftbg2">{{ item.GLLX }} </span>
                                <span v-if="item.GLLX == '高速公路'" class="leftbg leftbg3">{{ item.GLLX }} </span>
                                <span>“十四五”投资(亿元)：{{item.SSWZTZ}}</span>
                            </div>
                        </div>
                        <div class="right_item">
                            <!-- <i @click="showForm(item.Ld_guid, querynameseries)">
                <img
                  src="@/assets/mapimages/new/ic_info.png"
                  alt
                  title="查看属性"
                />
              </i> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 二三维切换 -->
        <div id="infoDiv">
            <input class="esri-component esri-widget--button esri-widget esri-interactive" type="button" @click="switchView" id="switch-btn" value="3D" />
        </div>
        <div class="imgDiv" v-if="imgshow">
            <div class="right_nav" @click="imgshow=false">
                <i>
                    <img src="@/assets/mapimages/maptools/popup_close_15d2283.gif" alt />
                </i>
            </div>
            <div class="title">
                <span class="lxbm">{{imglxbm}}</span>
                <span class="lc">{{imgzh}}</span>
                <span>(公里)</span>
            </div>
            <img :src="imgurl" alt="" class="img">
        </div>
        <div id="layersDiv" class="esri-widget" v-show="layerStyleControlShow">
            <div id="description">
                <span id="sliderValue">地图图层显隐</span>
            </div>
            <div id="legendDiv">
                <!-- 图层显隐 -->
                <LayersShow />
            </div>
        </div>

        <!-- 工具栏 -->
        <!-- <Tools ref="tools" /> -->
        <div class="MapLayers" :class="{MapLayers1:isdata}" @click="isdata=!isdata">
            <i>
                <img src="@/assets/icon/layer.png" alt v-if="!isdata" />
                <img src="@/assets/icon/laye_selectedr.png" alt v-else />
            </i>
            <span>图层组</span>
        </div>
        <div class="mapLayers" :class="{active:isdata}">
            <div class="title">图层组</div>
            <el-tree ref="tree" :data="data" show-checkbox :check-strictly='false' node-key="id" @check="handleChange" :default-expanded-keys="[10,1001,50, 5003]" :default-checked-keys="checkIds" :props="defaultProps"></el-tree>
        </div>
        <!-- 测量工具栏 -->
        <Meature v-show="toolbarDivShow" @distanceMeasurement="distanceMeasurement" @areaMeasurement="areaMeasurement" @clearMeasurements="clearMeasurements" />

        <!-- 坐标定位 -->
        <Coordinate v-show="coordinateShow" @position="position" />

        <!-- 桩号定位 -->
        <PilePoint v-show="PilePointShow" v-on:poptemplate="poptemplate" v-on:animation="animation" :token="token" />

        <!-- 底图切换 -->
        <LayerSwitch @switchLayer="switchLayer" />

        <div class="loading" :class="{ loadingActive: !loading }">
            <i class="d-center">
                <img src="@/assets/mapimages/new/map-loading.gif" alt />
            </i>
            <span>正在获取</span>
        </div>

        <!-- 弹窗 -->
        <div class="map-info z-indextop">

<!--            <div class="box box123123" v-show="roadShow" style="position: relative;" :class="{r105:!roadChildShow}">-->
<!--                <div class="leftMenu">-->
<!--                    <div class="ul" :class="{active:leftMenuActive==1}" @click="leftMenuActive=1;roadChildShow=true">-->
<!--                        <div class="li">-->
<!--                            <img src="@/assets/icon/nav-info1-1.png" class="img1" alt="">-->
<!--                            <img src="@/assets/icon/nav-info1.png" class="img2" alt="">-->
<!--                            <div class="t">项目详情</div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="ul" :class="{active:leftMenuActive==2}" @click="leftMenuActive=2;roadChildShow=true">-->
<!--                        <div class="li">-->
<!--                            <img src="@/assets/icon/nav-info2-1.png" class="img1" alt="">-->
<!--                            <img src="@/assets/icon/nav-info2.png" class="img2" alt="">-->
<!--                            <div class="t">前期工作进展</div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="ul">-->
<!--                        <div class="li" @click="leftMenuActive=3;roadChildShow=true">-->
<!--                            <img src="@/assets/icon/nav-info4-1.png" class="img1" alt="">-->
<!--                            <img src="@/assets/icon/nav-info4.png" class="img2" alt="">-->
<!--                            <div class="t">投资计划</div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    &lt;!&ndash;                    <div class="ul">&ndash;&gt;-->
<!--                    &lt;!&ndash;                        <div class="li" style="cursor: no-drop;">&ndash;&gt;-->
<!--                    &lt;!&ndash;                            <img src="@/assets/icon/nav-info3-1.png" class="img1" alt="">&ndash;&gt;-->
<!--                    &lt;!&ndash;                            <img src="@/assets/icon/nav-info3.png" class="img2" alt="">&ndash;&gt;-->
<!--                    &lt;!&ndash;                            <div class="t">固定资产</div>&ndash;&gt;-->
<!--                    &lt;!&ndash;                        </div>&ndash;&gt;-->
<!--                    &lt;!&ndash;                    </div>&ndash;&gt;-->
<!--                </div>-->
<!--                <div class="box-title1">-->
<!--                    <h3 class="tit" v-if="leftMenuActive==1">项目详情</h3>-->
<!--                    <h3 class="tit" v-if="leftMenuActive==2">前期工作进展</h3>-->
<!--                    <span class="fright">-->
<!--                        <a style="cursor: pointer;" class="btn-down" @click="roadChildShow = false">-->
<!--                            <img src="@/assets/icon/shouqi1.png" alt="" style="width: 13px;position: relative;top: -1px;">-->
<!--                        </a>-->
<!--                        &lt;!&ndash; <a style="cursor: pointer;" v-show="roadChildShow" @click="roadChildShow = false" class="btn-up"><i class="el-icon-arrow-up"></i></a> &ndash;&gt;-->
<!--                        <small style="user-select: none;"> | </small>-->
<!--                        <a style="cursor: pointer;" class="btn-close" @click="roadShow = false"><i class="el-icon-close" style="position: relative;top: 1px;"></i></a></span>-->
<!--                </div>-->
<!--                <div class="box-content box-content-sm" style="padding:0;width: 100%;overflow: auto;height: calc(100vh - 225px);">-->
<!--                    <div v-if="leftMenuActive==1" style="padding:10px 12px;">-->
<!--                        <Xmxqs :roadModel="roadModel" v-if="leftMenuActive==1" />-->
<!--                    </div>-->
<!--                    <div class="" v-if="leftMenuActive==2" style="padding:5px;">-->
<!--                        <el-table :data="QQtableData" border style="width: 100%">-->
<!--                            <el-table-column prop="jdmc" label="前期阶段" width="80"></el-table-column>-->
<!--                            <el-table-column prop="jhwcsj" label="计划完成时间" width="120"></el-table-column>-->
<!--                            <el-table-column prop="pfdw" label="批复单位" width="80"></el-table-column>-->
<!--                            <el-table-column prop="pfsj" label="批复时间" width="80"></el-table-column>-->
<!--                            <el-table-column prop="pfwjbt" label="批复文件标题" width="120"></el-table-column>-->
<!--                            <el-table-column prop="pfwh" label="批复文件文号" width="120"></el-table-column>-->
<!--                            <el-table-column width="150"  prop="name" label="附件" align="center">-->
<!--                                <template slot-scope="scope">-->
<!--                                    <el-popover trigger="hover" placement="top" v-if="scope.row.fj.length > 0">-->
<!--                                        <ul class="fj-list">-->
<!--                                            <li v-for="(item,i) in scope.row.fj" :key="i">-->
<!--                                                <a target="view_window" :href="baseUrl+item.url+item.name">-->
<!--                                                    {{item.name}}-->
<!--                                                </a>-->
<!--                                                &lt;!&ndash;                                    <el-link class="fj-del" icon="el-icon-delete" @click="unsetFiles(item,i)"></el-link>&ndash;&gt;-->
<!--                                            </li>-->
<!--                                        </ul>-->

<!--                                        <div slot="reference" class="name-wrapper">-->
<!--                                            <el-tag :type="scope.row.fj.length>0?'success':'danger'" size="small">已上传{{scope.row.fj.length}}个</el-tag>-->
<!--                                        </div>-->
<!--                                    </el-popover>-->
<!--                                </template>-->
<!--                            </el-table-column>-->
<!--                            <el-table-column prop="gzjzms" label="工作进展描述" width="300"></el-table-column>-->
<!--                        </el-table>-->

<!--                    </div>-->
<!--                    <div class="" v-if="leftMenuActive==3">-->
<!--                        <el-table :data="yearData"  border :default-sort="{ prop: 'SSDS', order: 'ascending' }" style="width: 100%">-->
<!--                            <el-table-column  prop="Year" :width="100" label="年度" align="center" sortable show-overflow-tooltip></el-table-column>-->
<!--                            <el-table-column  prop="CGS" label="固定资产投资计划" align="center" show-overflow-tooltip>-->
<!--                                <el-table-column  prop="CGS" label="车购税" align="center" show-overflow-tooltip></el-table-column>-->
<!--                                <el-table-column  prop="WH1" :width="300" align="center" label="车购税文号" show-overflow-tooltip ></el-table-column>-->
<!--                            </el-table-column>-->
<!--                            <el-table-column  prop="CGS" label="建设投资计划(大本计划)" align="center" show-overflow-tooltip>-->
<!--                                <el-table-column  prop="HJ" label="合计" align="center" show-overflow-tooltip></el-table-column>-->
<!--                                <el-table-column  prop="DFPT" label="地方配套" align="center" show-overflow-tooltip ></el-table-column>-->
<!--                                <el-table-column  prop="CZZJ" label="财政资金" align="center" show-overflow-tooltip ></el-table-column>-->
<!--                                <el-table-column  prop="ZXZQ" label="专项债券" align="center" show-overflow-tooltip ></el-table-column>-->
<!--                                <el-table-column  prop="YHDK" :width="220" align="center" label="成品油税费改革转移支付资金" show-overflow-tooltip ></el-table-column>-->
<!--                                <el-table-column  prop="CPY" label="银行贷款" align="center" show-overflow-tooltip ></el-table-column>-->
<!--                                <el-table-column  prop="QYZC" label="企业自筹" align="center" show-overflow-tooltip ></el-table-column>-->
<!--                                <el-table-column  prop="WH2" label="文号" :width="220" align="center" show-overflow-tooltip ></el-table-column>-->
<!--                            </el-table-column>-->
<!--                            <el-table-column width="150"  prop="name" label="附件" align="center">-->
<!--                                <template slot-scope="scope">-->
<!--                                    <el-popover trigger="hover" placement="top" v-if="scope.row.FJ.length > 0">-->
<!--                                        <ul class="fj-list">-->
<!--                                            <li v-for="(item,i) in scope.row.FJ" :key="i">-->
<!--                                                <a target="view_window" :href="baseUrl+item.url+item.name">-->
<!--                                                    {{item.name}}-->
<!--                                                </a>-->
<!--                                                &lt;!&ndash;                                    <el-link class="fj-del" icon="el-icon-delete" @click="unsetFiles(item,i)"></el-link>&ndash;&gt;-->
<!--                                            </li>-->
<!--                                        </ul>-->

<!--                                        <div slot="reference" class="name-wrapper">-->
<!--                                            <el-tag :type="scope.row.FJ.length>0?'success':'danger'" size="small">已上传{{scope.row.FJ.length}}个</el-tag>-->
<!--                                        </div>-->
<!--                                    </el-popover>-->
<!--                                </template>-->
<!--                            </el-table-column>-->
<!--                        </el-table>-->
<!--                    </div>-->
<!--                    <div class="" v-if="leftMenuActive==4">-->
<!--                        123-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
        </div>
        <el-dialog title="项目信息" width="60%" :visible.sync="showDialog" >
            <el-tabs v-model="tabIndex" @tab-click="handleClick" style="height: 500px">
                <el-tab-pane label="项目详情" name="0">
                    <Xmxqs :roadModel="roadModel" v-if="leftMenuActive==1" style="height: 400px" />
                </el-tab-pane>
                <el-tab-pane label="前期工作进展" name="1">
                    <el-table :data="QQtableData" border style="width: 100%">
                        <el-table-column prop="jdmc" label="前期阶段" width="80"></el-table-column>
                        <el-table-column prop="jhwcsj" label="计划完成时间" width="120"></el-table-column>
                        <el-table-column prop="pfdw" label="批复单位" width="80"></el-table-column>
                        <el-table-column prop="pfsj" label="批复时间" width="80"></el-table-column>
                        <el-table-column prop="pfwjbt" label="批复文件标题" width="120"></el-table-column>
                        <el-table-column prop="pfwh" label="批复文件文号" width="120"></el-table-column>
                        <el-table-column width="150"  prop="name" label="附件" align="center">
                            <template slot-scope="scope">
                                <el-popover trigger="hover" placement="top" v-if="scope.row.fj.length > 0">
                                    <ul class="fj-list">
                                        <li v-for="(item,i) in scope.row.fj" :key="i">
                                            <a target="view_window" :href="baseUrl+item.url+item.name">
                                                {{item.name}}
                                            </a>
                                            <!--                                    <el-link class="fj-del" icon="el-icon-delete" @click="unsetFiles(item,i)"></el-link>-->
                                        </li>
                                    </ul>

                                    <div slot="reference" class="name-wrapper">
                                        <el-tag :type="scope.row.fj.length>0?'success':'danger'" size="small">已上传{{scope.row.fj.length}}个</el-tag>
                                    </div>
                                </el-popover>
                            </template>
                        </el-table-column>
                        <el-table-column prop="gzjzms" label="工作进展描述" width="300"></el-table-column>
                    </el-table>
                </el-tab-pane>
                <el-tab-pane label="投资计划" name="2">
                    <el-table :data="yearData"  border :default-sort="{ prop: 'SSDS', order: 'ascending' }" style="width: 100%">
                        <el-table-column  prop="Year" :width="100" label="年度" align="center" sortable show-overflow-tooltip></el-table-column>
                        <el-table-column  prop="CGS" label="固定资产投资计划" align="center" show-overflow-tooltip>
                            <el-table-column  prop="CGS" label="车购税" align="center" show-overflow-tooltip></el-table-column>
                            <el-table-column  prop="WH1" :width="300" align="center" label="车购税文号" show-overflow-tooltip ></el-table-column>
                        </el-table-column>
                        <el-table-column  prop="CGS" label="建设投资计划(大本计划)" align="center" show-overflow-tooltip>
                            <el-table-column  prop="HJ" label="合计" align="center" show-overflow-tooltip></el-table-column>
                            <el-table-column  prop="DFPT" label="地方配套" align="center" show-overflow-tooltip ></el-table-column>
                            <el-table-column  prop="CZZJ" label="财政资金" align="center" show-overflow-tooltip ></el-table-column>
                            <el-table-column  prop="ZXZQ" label="专项债券" align="center" show-overflow-tooltip ></el-table-column>
                            <el-table-column  prop="YHDK" :width="220" align="center" label="成品油税费改革转移支付资金" show-overflow-tooltip ></el-table-column>
                            <el-table-column  prop="CPY" label="银行贷款" align="center" show-overflow-tooltip ></el-table-column>
                            <el-table-column  prop="QYZC" label="企业自筹" align="center" show-overflow-tooltip ></el-table-column>
                            <el-table-column  prop="WH2" label="文号" :width="220" align="center" show-overflow-tooltip ></el-table-column>
                        </el-table-column>
                        <el-table-column width="150"  prop="name" label="附件" align="center">
                            <template slot-scope="scope">
                                <el-popover trigger="hover" placement="top" v-if="scope.row.FJ.length > 0">
                                    <ul class="fj-list">
                                        <li v-for="(item,i) in scope.row.FJ" :key="i">
                                            <a target="view_window" :href="baseUrl+item.url+item.name">
                                                {{item.name}}
                                            </a>
                                            <!--                                    <el-link class="fj-del" icon="el-icon-delete" @click="unsetFiles(item,i)"></el-link>-->
                                        </li>
                                    </ul>

                                    <div slot="reference" class="name-wrapper">
                                        <el-tag :type="scope.row.FJ.length>0?'success':'danger'" size="small">已上传{{scope.row.FJ.length}}个</el-tag>
                                    </div>
                                </el-popover>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-tab-pane>
            </el-tabs>
        </el-dialog>
    </div>
</template>
<script>
    import { loadCss, loadModules } from "esri-loader";
    // import Tools from "@/components/AMap/Tools.vue"; //工具栏
    import Meature from "@/components/AMap/Meature.vue"; //测量工具栏
    import LayerSwitch from "@/components/AMap/LayerSwitch.vue"; //图层选择
    import LayersShow from "@/components/AMap/LayersShow.vue"; //图层列表
    import Coordinate from "@/components/AMap/Coordinate.vue"; //坐标定位
    import PilePoint from "@/components/AMap/PilePoint.vue"; //坐标定位
    import Search from "@/components/AMap/Search.vue"; //查询工具栏

    // import { debounce } from "@/plugins/common.js";
    import {
        apiDomainCss,
        apiDomainJs,
        RestUrl,
        BgUrl,
        LdUrl,
        trafficUrl,
        QueryUrl,
        DiMaoUrl,
        YingXiangUrl,
        amapImageUrl,
        amapStreetUrl,
        tdtUrl,
        planUrl,
        IdentifyByLXBMUrl,
    } from "../../map/url";
    import { imglists } from "../../map/imglist";
    import { GeoJSON, WFS } from "ol/format";
    import {
        subLayerIsVisible,
        createTintLayer,
        createTitle,
        sortNumbers,
        calculateAngle,
    } from "../../map/methods";

    // import "../../assets/mapcss/global.less";
    import "../../assets/mapcss/mappop.less";
    import "../../assets/mapcss/map.less";
    import Xmxqs from "@/components/Querynew/Xmxq.vue";

    export default {
        name: "App",
        components: {
            // Tools,
            Meature,
            LayerSwitch,
            LayersShow,
            Coordinate,
            PilePoint,
            Search,
            Xmxqs,
        },
        data() {
            return {
                noLine:['国道绥芬河至满洲里公路尚志阿城界至平山镇段','绥满高速公路哈尔滨至阿城段（谋划）','绥满高速哈尔滨至大庆段（谋划）','北漠高速五大连池至嫩江段','北漠高速嫩江至加格达奇段（谋划）',
                    '宝清支线机场连接线','逊克通用机场连接线','省道哈尔滨至亚布力公路尚志镇至一面坡镇段','省道哈尔滨至大安公路世贸大道至哈肇界段',
                    '省道名山至兴凯湖公路291一分场19队入点至291一分场30队出点','省道同江至汪清公路集贤镇至福利镇段','省道四季屯至鹤岗公路小桦林子村至小队段',
                    '省道方正至珲春公路牛心山经营所至新合桥段','省道阿城至扶余公路拉林河大桥','省道连崟至兴华公路新林至兴华段','省道逊克至孙吴公路逊克口岸至逊克（靠山村）段',
                    '国道北京至抚远公路哈尔滨四环路至呼兰段','国道鹤岗至大连公路小五站镇至勃利鸡东界段','国道同江至哈尔滨公路二龙山镇至大榆树镇段',
                    '国道同江至哈尔滨公路友谊至集贤段','国道同江至哈尔滨公路佳木斯依兰界至依兰方正界段','国道嘉荫至临江公路南岔至铁力段','国道绥芬河至满洲里公路尚志过境段','国道绥芬河至满洲里公路尚志阿城界至平山镇段','国道丹东至阿勒泰公路八面通镇至鸡穆界段'
                    ,'国道丹东至阿勒泰公路鸡穆界至鸡西段','国道丹东至阿勒泰公路鸡东密山界至新华村段','国道丹东至阿勒泰公路卧牛湖至黑呼界段','国道三莫公路雪乡至滑雪场段','国道三莫公路滑雪场至亚布力段','国道集贤至当壁公路宝山区至宝清段'],
                showDialog:false,
                yearData:[],
                baseUrl: "http://82.156.50.94:8002/",
                QQtableData: [],
                leftMenuActive: 1,
                isdata: false,
                data: [
                    {
                        id: 10,
                        label: "十四五规划",
                        children: [
                            {
                                id: 1001,
                                label: "公路",
                                children: [
                                    {
                                        id: 100101,
                                        label: "高速公路",
                                        server: "planLayer|1",
                                    },
                                    {
                                        id: 100102,
                                        label: "普通国省道",
                                        server: "planLayer|0",
                                    },
                                ],
                            },
                            {
                                id: 1002,
                                label: "铁路",
                                disabled: true,
                            },
                            {
                                id: 1003,
                                label: "机场",
                                disabled: true,
                            },
                            {
                                id: 1004,
                                label: "水运",
                                disabled: true,
                            },
                            {
                                id: 1005,
                                label: "枢纽场站",
                                disabled: true,
                            },
                        ],
                    },
                    {
                        id: 12,
                        label: "统计年报",
                        children: [
                            {
                                id: 1202,
                                label: "省道高速",
                                server: "ldLayers|22",
                            },
                            {
                                id: 1203,
                                label: "国道",
                                server: "ldLayers|17",
                            },
                            {
                                id: 1204,
                                label: "省道",
                                server: "ldLayers|21",
                            },
                            {
                                id: 1205,
                                label: "县道",
                                server: "ldLayers|25",
                            },
                            {
                                id: 1206,
                                label: "乡道",
                                server: "ldLayers|28",
                            },
                            {
                                id: 1207,
                                label: "专用公路",
                                server: "ldLayers|31",
                            },
                            {
                                id: 1208,
                                label: "村道",
                                server: "ldLayers|34",
                            },
                            {
                                id: 1209,
                                label: "桥梁",
                                server: "ldLayers|9",
                            },
                            {
                                id: 1210,
                                label: "隧道",
                                server: "ldLayers|10",
                            },
                            {
                                id: 1211,
                                label: "涵洞",
                                server: "ldLayers|12",
                            },
                        ],
                    },
                    {
                        id: 13,
                        label: "三区三线",
                        children: [
                            {
                                id: 1301,
                                label: "永久基本农田",
                                server: "ntLayers",
                            },
                            {
                                id: 1302,
                                label: "生态保护红线",
                                server: "hxLayers",
                            },
                        ],
                    },
                    {
                        id: 14,
                        label: "列养",
                        children: [
                            {
                                id: 1401,
                                label: "国道",
                                children: [
                                    {
                                        id: 140101,
                                        label: "列养",
                                        server: "lyLayers|5",
                                    },
                                    {
                                        id: 140102,
                                        label: "未列养",
                                        server: "lyLayers|4",
                                    },
                                    {
                                        id: 140103,
                                        label: "砂石",
                                        server: "lyLayers|3",
                                    },
                                ],
                            },
                            {
                                id: 1402,
                                label: "省道",
                                children: [
                                    {
                                        id: 140201,
                                        label: "列养",
                                        server: "lyLayers|2",
                                    },
                                    {
                                        id: 140202,
                                        label: "未列养",
                                        server: "lyLayers|1",
                                    },
                                    {
                                        id: 140203,
                                        label: "砂石",
                                        server: "lyLayers|0",
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        id: 15,
                        label: "路况评定",
                        children: [
                            {
                                id: 1501,
                                label: "路况评定",
                                server: "lkLayers",
                            },
                            {
                                id: 1502,
                                label: "现场照片",
                                server: "imageLayers",
                            },
                        ],
                    },
                ],
                defaultProps: {
                    children: "children",
                    label: "label",
                },
                checkIds: [],
                activeId: 0,
                roadShow: true,
                roadChildShow: true,
                roadModel: {},
                queryShow: false, //查询结果显隐
                coordinateShow: false, //坐标定位显隐
                PilePointShow: false, //桩号定位显隐
                bgLayer: null,
                dmLayer: null,
                yxLayer: null,
                ldLayer: null,
                trafficLayer: null,
                planLayer: null,
                TintLayer: null,
                amapImageLayer: null,
                amapStreetLayer: null,
                tdtLayer: null,
                map: null,
                mapView: null,
                sceneView: null,
                activeView: null,
                legend: null,
                draw: null,
                mapChange: null,
                mapChangePlan: null,
                mapChangeImg: null,
                activeWidget: null, //测量动态对象
                toolbarDivShow: false, //测量工具栏显隐
                switchLayerIndex: 1, //底图切换索引
                mapSelected: false, //地图模式
                menuX: 0,
                menuY: 0,
                currentLocationIndex: 0,
                legendHide: false, //图例隐藏
                sketchHide: false, //地图编缉工具隐藏
                loading: false, //正在加载中
                layerStyleControlShow: false, //图层显隐
                token: "", //GIS TOKEN
                LkxzShow: false, //路况图层显隐

                imgCoord: require("@/assets/mapimages/maptools/BluePin1LargeB.png"), //定位图标
                img1: require("@/assets/mapimages/maptools/arrow.png"),
                img3: require("@/assets/mapimages/new/infobox-close.png"),
                img4: require("@/assets/mapimages/new/ic_location_blue.png"),
                img5: require("@/assets/mapimages/new/ic_info.png"),
                img6: require("@/assets/mapimages/ic_wrong.png"),

                popupData: {}, //弹出框数据

                drawAction: null,

                measurement: null,
                webMercatorUtils: null,
                Graphic: null,
                QueryTask: null,
                Query: null,
                Point: null,
                watchUtils: null,
                IdentifyParameters: null,
                Extent: null,
                SpatialReference: null,
                Polygon: null,
                find: null,
                FindParameters: null,
                GeometryEngine: null, //画框
                TextSymbol: null,
                actions: null, //画框
                findData: [],

                //图层组
                ldLayers: null, //年报
                lyLayers: null, //列养
                lkLayers: null, //路况
                ltLayers: null, //路况图片
                hxLayers: null, //红线
                ncLayers: null, //农田
                imageLayers: [],
                imgurl: "",
                imglxbm: "",
                imgzh: "",
                imgshow: false,
                tabIndex:'0',
                //图层组
            };
        },

        methods: {
            handleClick(val){
                this.tabIndex = val.paneName
            },
            //获取历年计划
            postData1(){

                //项目名称
                var query_year = {
                    Name: "Year",
                    Value: this.formInline.year,
                    DisplayType: "Equal",
                };
                var query_Id = {
                    Name: "Plan_Road_Inv_ID",
                    Value: this.oldYearId,
                    DisplayType: "Equal",
                };


                var postData = {
                    page: 1,
                    rows: 20,
                    Sort: "DSBM",
                    Order: "desc",
                    wheres: JSON.stringify([
                        query_year,
                        query_Id
                    ]),
                };
                if(!this.addStatus){
                    postData.value = this.detail.id
                }
                return postData;
            },
            setVis(server, checked) {
                if (!server) return;
                let ser = server.split("|");
                if (ser.length == 2) {
                    let find = this.map.findLayerById(ser[0]);
                    let info = find.findSublayerById(parseInt(ser[1]));
                    info.visible = checked;
                } else {
                    let find = this.map.findLayerById(ser[0]);
                    find.visible = checked;
                }
            },
            handleChange(data, checked) {
                this.clearScreen();
                var ldids = [
                    12, 1201, 1202, 1203, 1204, 1205, 1206, 1207, 1208, 1209, 1210,
                    1211,
                ];
                if (ldids.indexOf(data.id) >= 0) {
                    this.Choiceof();
                }
                if (data.id == "1502" || data.id == "15") {
                    this.ChoiceofImg();
                }
                var half = checked.halfCheckedKeys;
                var keys = checked.checkedKeys;
                var id = data.id;
                var iskeys = keys.indexOf(data.id) >= 0;
                var ishalf = half.indexOf(id) >= 0;
                if (!data.children) {
                    this.setVis(data.server, iskeys);
                } else {
                    data.children.map((r) => {
                        if (r.children) {
                            r.children.map((rr) => {
                                if (rr.server) {
                                    let check = iskeys || ishalf ? true : false;
                                    this.setVis(rr.server, check);
                                }
                            });
                        } else {
                            if (r.server) {
                                let check = iskeys || ishalf ? true : false;
                                this.setVis(r.server, check);
                            }
                        }
                    });
                }
            },
            //关闭查询结果栏
            closeQueryResult() {
                this.queryShow = false;
            },
            locDsQx(x, y) {
                // console.log(x, y);
                this.activeView.goTo({
                    target: [x, y],
                    zoom: 9,
                });
            },
            /**
             * 查询
             */
            doFind(layerIds, searchText, postData) {
                // console.log(this.planLayer);
                // this.planLayer.setDefinitionExpression("汇总序号 = 118");
                this.loading = true;
                this.queryShow = false;
                this.findData = [];
                // this.planLayer.findSublayerById(1).definitionExpression = "XMMC in ('省道连崟至兴华公路新林至兴华段','省道四季屯至鹤岗公路汤旺河至乌伊岭段')",

                // console.log(postData, 333);
                this.http
                    .post(
                        "/api/Plan_road/GetPageData",
                        postData,
                        "正在加载，请稍候..."
                    )
                    .then((res) => {
                        if (res.status == 0) {
                            var ddd = [];
                            res.rows.map((train) => {
                                ddd.push("'" + train.XMMC + "'");
                            });
                            ddd = [...new Set(ddd)];

                            // console.log(ddd, ddd.join(","));

                            this.planLayer.findSublayerById(
                                1
                            ).definitionExpression =
                                "XMMC in (" +
                                ddd.join(",") +
                                ") or BZ in (" +
                                ddd.join(",") +
                                ")";
                            this.planLayer.findSublayerById(
                                0
                            ).definitionExpression =
                                "XMMC in (" +
                                ddd.join(",") +
                                ") or BZ in (" +
                                ddd.join(",") +
                                ")";
                            this.ChoicePlan();

                            this.findData = res.rows;
                            this.findData.filter((item) => {
                                if (this.noLine.indexOf(item.XMMC) > -1) {
                                    item.isShowPosi = false
                                }else{
                                    item.isShowPosi = true
                                }
                            });
                            this.loading = false;
                            this.queryShow = true;
                        }
                    });

                // var params = new this.FindParameters({
                //     layerIds: layerIds,
                //     searchText: searchText,
                //     returnGeometry: true,
                // });
                // console.log(params);
                // this.find
                //     .find(QueryUrl, params)
                //     .then(this.showResults)
                //     .catch(this.rejectedPromise);
            },
            showResults(response) {
                var results = response.results;
                results.forEach((element) => {
                    var item = {};
                    item.layerId = element.layerId;
                    item.layerName = element.layerName;
                    item.value = element.value;
                    item.feature = element;
                    item.id =
                        element.layerId +
                        "-" +
                        element.feature.attributes["OBJECTID"];
                    this.findData.push(item);
                });

                this.queryShow = true;
                this.loading = false;
            },
            rejectedPromise(error) {
                console.error("Promise didn't resolve: ", error.message);
                this.loading = false;
            },
            getYear(ID){
                console.log(11111111,ID)
                this.http
                    .post(
                        "/api/Plan_road_investment/GetPageData",
                        {page:1,rows:1, Sort: "DSBM", Order: "desc", wheres: JSON.stringify([{
                                Name: "XMID",
                                Value: ID,
                                DisplayType: "Equal",
                            } ]),},
                        "正在请求数据，请稍候..."
                    )
                    .then((res) => {
                        if (res.status == 0 &&  res.rows.length != 0) {
                            res.rows[0].ID
                            this.http.post('/api/Plan_road_investment_old_year/GetPageData',{page: 1,rows: 20,  Sort: "Year",  Order: "desc",   wheres: JSON.stringify([
                                    {
                                        Name: "Plan_Road_Inv_ID",
                                        Value:  res.rows[0].ID,
                                        DisplayType: "Equal",
                                    },
                                    {
                                        Name: "Year",
                                        Value: '',
                                        DisplayType: "Equal",
                                    }
                                ]),}).then(res=> {
                                this.yearData = res.rows
                                this.yearData.map((item,index)=>{
                                    var a = []
                                    if(item.FJ != '' && item.FJ != null){
                                        item.FJ = item.FJ.split(';')
                                        item.FJ.map((subItem)=>{
                                            subItem = {url:subItem.substring(0,subItem.lastIndexOf("/") + 1),name:subItem.substring(subItem.lastIndexOf("/") + 1,subItem.length)}
                                            a.push(subItem)
                                        })
                                        item.FJ = a
                                    }else {
                                        item.FJ = []
                                    }

                                })
                            })
                            // this.total = res.total;
                            // this.tableData = res.rows;
                        }else{
                            this.yearData = []
                        }
                    });
            },
            location(feature) {
                this.showDialog = true
                this.tabIndex = '0'
                console.log(feature, "id");
                if (!feature.isShowPosi) return;
                var that = this;
                this.getYear(feature.ID)
                this.clearScreen();
                // var graphic = feature.feature;
                // console.log(JSON.stringify(feature));
                this.http
                    .get("/api/Plan_high_national_early_node/getNodeByXmId", {
                        sswghxmid: feature.ID,
                    })
                    .then((res) => {
                        if (res.data.length > 0) {
                            res.data.map((item,index)=>{
                                var a = []
                                if(item.fj != ''){
                                    item.fj = item.fj.split(';')
                                    item.fj.map((subItem)=>{
                                        subItem = {url:subItem.substring(0,subItem.lastIndexOf("/") + 1),name:subItem.substring(subItem.lastIndexOf("/") + 1,subItem.length)}
                                        a.push(subItem)
                                    })
                                    item.fj = a
                                }else {
                                    item.fj = []
                                }

                            })
                            this.QQtableData = res.data;
                        } else {
                            this.QQtableData = [];
                        }
                    });

                var where = "";
                var url = planUrl;
                // "http://39.100.134.93:6080/arcgis/rest/services/plan/MapServer/";
                if (feature.GLLX == "高速公路") {
                    where = "XMMC = '" + feature.XMMC + "'";
                    url = url + "/1";
                } else {
                    where = "XMMC = '" + feature.XMMC + "'";
                    url = url + "/0";
                }
                // console.log(feature);
                this.roadModel = feature;
                this.roadShow = true;
                this.roadChildShow = true;
                // console.log(url, where, feature.GLLX);
                var queryTask = new this.QueryTask(url);
                var query = new this.Query();
                query.where = where;
                query.outSpatialReference = { wkid: 3857 };
                query.returnGeometry = true;
                query.outFields = ["*"];
                queryTask.execute(query).then(that.showResultsLX);

                // if (graphic.geometry.type == "polyline") {
                //     var center, arrowStartIndex, arrowEndIndex; //中心点坐标
                //     var pc = graphic.geometry.paths[0];
                //     if (pc.length % 2 == 0) {
                //         arrowEndIndex = Math.round(pc.length / 2);
                //         arrowStartIndex = Math.round(pc.length / 2 - 1);

                //         var extent = new this.Extent(
                //             pc[arrowStartIndex][0],
                //             pc[arrowStartIndex][1],
                //             pc[arrowEndIndex][0],
                //             pc[arrowEndIndex][1],
                //             new this.SpatialReference({ wkid: 3857 })
                //         );
                //         center = [extent.center.x, extent.center.y];
                //     } else {
                //         arrowEndIndex = Math.round(pc.length / 2 + 1);
                //         arrowStartIndex = Math.round(pc.length / 2 - 1);
                //         center = pc[Math.round(pc.length / 2)];
                //     }
                //     var centerPoint = {
                //         geometry: {
                //             type: "point",
                //             x: center[0],
                //             y: center[1],
                //             spatialReference: { wkid: 3857 },
                //         },
                //     };
                //     var popGraphic = new this.Graphic(centerPoint);
                //     popGraphic.pop = {
                //         x: center[0],
                //         y: center[1],
                //     };
                //     popGraphic.attr = {
                //         x: center[0],
                //         y: center[1],
                //     };
                //     this.activeView.graphics.add(popGraphic);
                //     this.activeView.goTo(graphic.geometry.extent);
                // } else {
                //     graphic.pop = {
                //         x: graphic.geometry.x,
                //         y: graphic.geometry.y,
                //     };
                //     graphic.attr = {
                //         x: graphic.geometry.x,
                //         y: graphic.geometry.y,
                //     };
                //     this.activeView.graphics.add(graphic);
                //     this.activeView.goTo({
                //         target: graphic.geometry,
                //         zoom: 10,
                //     });
                // }

                // this.showFeature(feature);
            },
            /**
             * 图层显隐
             */
            achangeLayer(layers) {
                layers.forEach((layer) => {
                    var filter = this.ldLayer.sublayers.filter(
                        (v) => v.id == layer.id
                    );
                    filter.forEach((f) => {
                        f.visible = layer.visible;
                    });
                });
            },
            //整线定位
            showResultsLX(featureSet) {
                console.log(featureSet, 456);
                if (featureSet.features.length <= 0) {
                    this.$message.error("无查询结果");
                    // this.roadShow = false;
                    return false;
                }
                // if(this.findData.findIndex(r=>r.XMMC == featureSet))
                var resultFeatures = featureSet.features;
                for (var i in resultFeatures) {
                    var graphic = resultFeatures[i];
                    // this.roadModel = graphic.attributes;
                    // console.log(this.roadModel, 678);
                    // this.roadShow = true;
                    // this.roadChildShow = true;
                    this.showPlanFeature({ feature: graphic });
                    // var geo = graphic.geometry;
                    // if (geo.type == "polyline") {
                    // var pc = graphic.geometry.paths[0];
                    // var start = pc[0]; //起点坐标
                    // var end = pc[pc.length - 1]; //止点坐标

                    // var center; //中心点坐标
                    // var arrowStartIndex, arrowEndIndex;

                    // if (pc.length % 2 == 0) {
                    //     arrowEndIndex = Math.round(pc.length / 2);
                    //     arrowStartIndex = Math.round(pc.length / 2 - 1);
                    //     var extent = new this.Extent(
                    //         pc[arrowStartIndex][0],
                    //         pc[arrowStartIndex][1],
                    //         pc[arrowEndIndex][0],
                    //         pc[arrowEndIndex][1],
                    //         new this.SpatialReference({ wkid: 3857 })
                    //     );
                    //     center = [extent.center.x, extent.center.y];
                    // } else {
                    //     arrowEndIndex = Math.round(pc.length / 2 + 1);
                    //     arrowStartIndex = Math.round(pc.length / 2 - 1);
                    //     center = pc[Math.round(pc.length / 2)];
                    // }

                    // var startPoint = {
                    //     geometry: {
                    //         type: "point",
                    //         x: start[0],
                    //         y: start[1],
                    //         spatialReference: { wkid: 3857 },
                    //     },
                    //     symbol: {
                    //         color: [255, 0, 0],
                    //         size: 10,
                    //         type: "simple-marker",
                    //     },
                    // };

                    // var endPoint = {
                    //     geometry: {
                    //         type: "point",
                    //         x: end[0],
                    //         y: end[1],
                    //         spatialReference: { wkid: 3857 },
                    //     },
                    //     symbol: {
                    //         color: [0, 128, 0],
                    //         size: 10,
                    //         type: "simple-marker",
                    //     },
                    // };

                    // var centerPoint = {
                    //     geometry: {
                    //         type: "point",
                    //         x: center[0],
                    //         y: center[1],
                    //         spatialReference: { wkid: 3857 },
                    //     },
                    // };

                    // var gStart = new this.Graphic(startPoint);
                    // var gEnd = new this.Graphic(endPoint);
                    // var gCenter = new this.Graphic(centerPoint);
                    // var angle = calculateAngle(
                    //     pc[arrowStartIndex],
                    //     pc[arrowEndIndex]
                    // ); //计算图形符号角度
                    // var arrowSymbol = {
                    //     url: this.img1,
                    //     height: "40px",
                    //     width: "40px",
                    //     type: "picture-marker",
                    //     angle: angle,
                    // };
                    // gCenter.symbol = arrowSymbol; //设置图形符号
                    // //设置文字符号，显示路段序列号

                    // var textSymbol = {
                    //     type: "text",
                    //     text:
                    //         graphic.attributes.Ldxlh ||
                    //         graphic.attributes.ldxlh,
                    //     color: [255, 255, 255],
                    //     font: { size: 8 },
                    //     xoffset: 0,
                    //     yoffset: -3,
                    // };

                    // var gCenterFont = new this.Graphic(centerPoint);

                    // gCenterFont.symbol = textSymbol;

                    // graphic.symbol = {
                    //     type: "simple-line",
                    //     color: "black",
                    //     width: 4,
                    // };
                    // this.activeView.graphics.add(graphic);

                    // this.activeView.graphics.add(gStart);
                    // this.activeView.graphics.add(gEnd);
                    // this.activeView.graphics.add(gCenter);
                    // this.activeView.graphics.add(gCenterFont);

                    var fullExtent;
                    if (i == 0) {
                        fullExtent = graphic.geometry.extent;
                    } else {
                        fullExtent = fullExtent.union(graphic.geometry.extent);
                    }
                    // }
                }
                this.activeView.goTo(fullExtent);
                this.loading = false;
            },
            /**
             * 坐标定位
             */
            position(x, y) {
                this.activeView.graphics.removeAll();
                if (x && y) {
                    var point = {
                        geometry: {
                            type: "point",
                            x: x,
                            y: y,
                            spatialReference: { wkid: 4326 },
                        },
                    };
                    var mercatorPoint =
                        this.webMercatorUtils.geographicToWebMercator(
                            point.geometry
                        );
                    var picSymbol = {
                        url: this.imgCoord,
                        height: "40px",
                        width: "40px",
                        type: "picture-marker",
                    };
                    var graphic = new this.Graphic({
                        geometry: mercatorPoint,
                        symbol: picSymbol,
                    });

                    this.activeView.graphics.add(graphic);
                    this.activeView.goTo({
                        target: graphic.geometry,
                        zoom: 10,
                    });
                } else {
                    this.$toastr("输入的参数不正确");
                }
            },
            /**
             * 国省道地图显隐
             */
            showGSD() {
                this.ldLayer.visible = !this.ldLayer.visible;
                // this.trafficLayer.visible = false;
                // this.LkxzShow = false;
            },
            /**
             * 路况地图显隐
             */
            showLk() {
                this.LkxzShow = !this.LkxzShow;
                if (this.LkxzShow) {
                    // this.ldLayer.visible = false;
                    this.trafficLayer.visible = true;
                } else {
                    // this.ldLayer.visible = true;
                    this.trafficLayer.visible = false;
                }
            },
            /**
             * 图例显隐
             */
            showLegend() {
                this.legendHide = !this.legendHide;
                if (this.legendHide) {
                    // this.activeView.ui.padding = { top: 60, right: 10 };
                    this.activeView.ui.add(this.legend, "bottom-left");
                } else {
                    this.activeView.ui.remove(this.legend);
                }
            },
            distanceMeasurement() {
                const type = this.activeView.type;
                this.measurement.activeTool =
                    type.toUpperCase() === "2D" ? "distance" : "direct-line";
            },
            areaMeasurement() {
                console.log(this.measurement);

                this.measurement.activeTool = "area";

                console.log(this.measurement);
            },
            clearMeasurements() {
                this.measurement.clear();
            },
            //点击添加点动画
            animation() {
                if (this.activeView.graphics.items.length > 0) {
                    this.activeView.graphics.items.forEach((v) => {
                        if ("attr" in v) {
                            var dom = document.getElementById("point" + v.uid + "");
                            var point = this.activeView.toScreen(
                                new this.Point({
                                    x: v.attr.x,
                                    y: v.attr.y,
                                    spatialReference: {
                                        wkid: 3857,
                                    },
                                })
                            );
                            if (!dom) {
                                dom = document.createElement("div");
                                dom.className = "pulse";
                                dom.id = "point" + v.uid;
                                document.getElementById("viewDiv").appendChild(dom);
                            }
                            dom.style = `left:${point.x - 10}px;top:${
                                point.y - 10
                            }px`;
                        }
                    });
                }
            },
            poptemplate(value) {
                if (this.activeView.graphics.items.length > 0) {
                    this.activeView.graphics.items.forEach((v) => {
                        if ("pop" in v) {
                            var div = document.getElementById("pop" + v.uid + "");
                            var point = this.activeView.toScreen(
                                new this.Point({
                                    x: v.pop.x,
                                    y: v.pop.y,
                                    spatialReference: {
                                        wkid: 3857,
                                    },
                                })
                            );
                            if (!div && typeof value == "string") {
                                div = document.createElement("div");
                                var div1 = document.createElement("div");
                                var div2 = document.createElement("div");
                                div2.className = "angle";
                                div.id = "pop" + v.uid;
                                div.className = "poptemplate";
                                document.getElementById("viewDiv").appendChild(div);
                                div.appendChild(div1);
                                div.appendChild(div2);
                                div1.innerHTML = value;
                            }
                            if (div) {
                                var height = div.offsetHeight;
                                div.style = `left:${point.x - 140}px;top:${
                                    point.y - 10 - height
                                }px`;
                                //  div.style = `left:${point.x - 140}px;top:${
                                //     point.y - 10 - height
                                // }px`;
                            }
                        }
                    });
                }
            },
            /**
             * 底图切换
             */
            switchLayer(index) {
                this.clearScreen();
                this.bgLayer.visible = false;
                this.dmLayer.visible = false;
                this.yxLayer.visible = false;
                this.tdtLayer.visible = false;
                // this.amapthis.ImageLayer.visible = false;
                this.amapStreetLayer.visible = false;
                this.switchLayerIndex = index;
                switch (index) {
                    case 0:
                        this.dmLayer.visible = true;
                        break;
                    case 1:
                        this.bgLayer.visible = true;
                        break;
                    case 2:
                        this.yxLayer.visible = true;
                        break;
                    // case 3:
                    //     // this.amapthis.ImageLayer.visible = true;
                    //     // this.amapStreetLayer.visible = true;
                    //     break;
                    case 4:
                        this.tdtLayer.visible = true;
                        break;
                }
            },
            /**
             * 测量桩号
             */
            measureClick(event) {
                //判断是否鼠标左键
                if (event.button != 0) {
                    return false;
                }
                this.loading = true;
                this.clearScreen();
                var params = new this.IdentifyParameters();
                params.tolerance = 10;
                params.layerOption = "top";
                //定义一个数组存放可见的图层
                let VisibleLayers = [];
                //获取所有图层
                this.ldLayer.allSublayers.items.forEach((v) => {
                    if (subLayerIsVisible(v, this.activeView.scale)) {
                        VisibleLayers.push(v.id);
                    }
                });

                // params.layerIds = this.list2;
                params.layerIds = VisibleLayers.sort(sortNumbers);
                params.width = this.activeView.width;
                params.height = this.activeView.height;
                params.geometry = event.mapPoint;
                params.mapExtent = this.activeView.extent;
                // document.getElementById("viewDiv").style.cursor = "wait";

                var identifyTaskMeasure = new this.IdentifyTask(LdUrl);
                identifyTaskMeasure.execute(params).then((res) => {
                    this.loading = false;
                    this.popupData.results = [];
                    var shuzu = res.results[0]; //数组 随便存一下 名字随便取的
                    if (shuzu != undefined) {
                        this.popupData.results.push(shuzu);
                    }
                    this.popupData.results.map((result) => {
                        this.popupData.layerName = result.layerName;
                        if (
                            this.popupData.layerName === "国道" ||
                            this.popupData.layerName === "省道" ||
                            this.popupData.layerName === "县道" ||
                            this.popupData.layerName === "乡道" ||
                            this.popupData.layerName === "专用公路" ||
                            this.popupData.layerName === "村道"
                        ) {
                            let layerIndex;
                            this.$PileTable.forEach((v) => {
                                if (v.title == this.popupData.layerName) {
                                    layerIndex = v.index;
                                }
                            });
                            let formData = {
                                LXBM: result.feature.attributes.LXBM,
                                mapX: event.mapPoint.x,
                                mapY: event.mapPoint.y,
                                resolution: this.activeView.resolution,
                                layerIndex: layerIndex,
                                Lxfxdm: result.feature.attributes.Lxfxdm,
                                Nd: this.$nd,
                                f: "pjson",
                            };
                            this.$http({
                                methods: "get",
                                url: IdentifyByLXBMUrl,
                                params: {
                                    LXBM: formData.LXBM,
                                    mapX: "M" + formData.mapX,
                                    mapY: "M" + formData.mapY,
                                    resolution: "M" + formData.resolution,
                                    layerIndex: "M" + formData.layerIndex,
                                    Lxfxdm: "_" + formData.Lxfxdm,
                                    Nd: "_" + formData.Nd,
                                    f: formData.f,
                                    token: this.token,
                                },
                            })
                                .then((res) => {
                                    var str = res.data.replace(/'/g, '"');
                                    let data = JSON.parse(str);
                                    if (data != {}) {
                                        data.LXMC = result.feature.attributes.LXMC;
                                        data.LXBM = result.feature.attributes.LXBM;
                                        data.Ldjsdj =
                                            result.feature.attributes.Ldjsdj;
                                        data.Ldlmlx =
                                            result.feature.attributes.Ldlmlx;
                                        this.pileMeasurePop(data);
                                    }
                                })
                                .catch((error) => {
                                    4;
                                    console.log(error);
                                    this.loading = false;
                                });
                        }
                    });
                });
            },
            /**
             * 桩号定位结果
             */
            pileMeasurePop(res) {
                //桩号测量弹出框
                let graphic = res;
                var x = res.x;
                var y = res.y;
                var popPoint = {
                    geometry: {
                        type: "point",
                        x: x,
                        y: y,
                        spatialReference: { wkid: 3857 },
                    },
                    symbol: {
                        color: "rgba(255,255,255,0)",
                        size: 0,
                        type: "simple-marker",
                        // style: "square"
                    },
                };
                var popGraphic = new this.Graphic(popPoint);
                popGraphic.pop = {
                    x: x,
                    y: y,
                };
                this.activeView.graphics.add(popGraphic);
                var dianPoint = {
                    geometry: {
                        type: "point",
                        x: x,
                        y: y,
                        spatialReference: { wkid: 3857 },
                    },
                    symbol: {
                        color: "rgba(255,255,255,0)",
                        size: 0,
                        type: "simple-marker",
                    },
                };
                let gEnd = new this.Graphic(dianPoint);
                gEnd.attr = {
                    x: x,
                    y: y,
                };
                this.activeView.graphics.add(gEnd);
                var name = "测量";
                let title = createTitle(name, graphic);
                this.activeView.goTo({
                    target: popPoint,
                    speedFactor: 6,
                    easing: "linear",
                });
                this.poptemplate(title);
                this.animation();
                this.loading = false;
            },

            //全屏
            goTo() {
                this.mapView.goTo({
                    target: this.bgLayer.fullExtent,
                });
                this.mouseMenuState("none");
            },
            /**
             * 地图拾取
             */
            ChoiceofImg() {
                this.activeView.graphics.removeAll();
                // this.$refs.tools.mouseState = 2;
                this.mapSelected = true;
                this.mouseMenuState("none");

                if (this.mapChangeImg != null) {
                    this.mapChangeImg.remove();
                }
                // this.planLayer.findSublayerById(0).visible = false;
                // this.planLayer.findSublayerById(1).visible = false;
                this.mapChangeImg = this.activeView.on(
                    "click",
                    this.imgExecuteIdentifyTask
                );
            },
            Choiceof() {
                this.activeView.graphics.removeAll();
                // this.$refs.tools.mouseState = 2;
                this.mapSelected = true;
                this.mouseMenuState("none");

                if (this.mapChange != null) {
                    this.mapChange.remove();
                }
                // this.planLayer.findSublayerById(0).visible = false;
                // this.planLayer.findSublayerById(1).visible = false;
                this.mapChange = this.activeView.on(
                    "click",
                    this.executeIdentifyTask
                );
            },
            ChoicePlan() {
                console.log("ck");
                this.activeView.graphics.removeAll();
                // this.$refs.tools.mouseState = 4;
                this.mapSelected = true;
                this.mouseMenuState("none");

                if (this.mapChangePlan != null) {
                    this.mapChangePlan.remove();
                }
                // this.planLayer.visible = true;
                this.planLayer.findSublayerById(0).visible = true;
                this.planLayer.findSublayerById(1).visible = true;
                this.mapChangePlan = this.activeView.on(
                    "click",
                    this.planExecuteIdentifyTask
                );
            },
            //地图移动
            mapMove() {
                this.activeView.graphics.removeAll();
                // this.$refs.tools.mouseState = 1;
                this.mapSelected = false;
                // this.planLayer.visible = false;
                this.planLayer.findSublayerById(0).visible = false;
                this.planLayer.findSublayerById(1).visible = false;
                this.mouseMenuState("none");
            },
            //测量桩号
            mapMeasure() {
                this.activeView.graphics.removeAll();
                // this.$refs.tools.mouseState = 3;
                this.mapSelected = false;
                this.mouseMenuState("none");
                if (this.mapChange != null) {
                    this.mapChange.remove();
                }
                this.mapChange = this.activeView.on("click", this.measureClick);
            },
            mouseMenuState(state) {
                var mouseMenu = document.getElementById("MapMouse");
                if (mouseMenu) {
                    mouseMenu.style = "display:" + state;
                }
            },
            //清屏
            clearScreen() {
                this.activeView.graphics.removeAll();
                // this.planLayer.findSublayerById(1).visible = false;
                // this.planLayer.findSublayerById(0).visible = false;
                // this.ldLayer.visible = false;
                // this.trafficLayer.visible = false;
                //清空弹窗
                var infos = document.querySelectorAll(".poptemplate");
                if (infos.length > 0) {
                    infos.forEach((v) => {
                        v.remove();
                    });
                }

                //清空地图渲染动画
                var dom = document.querySelectorAll(".pulse");
                if (dom.length > 0) {
                    dom.forEach((v) => {
                        v.remove();
                    });
                }
                this.mouseMenuState("none");
            },
            //控制鼠标右键菜单出现位置
            folderMouseControl(e) {
                var obj = document.getElementById("viewDiv");
                function getAbsLeft(obj) {
                    var l = obj.offsetLeft;
                    while (obj.offsetParent != null) {
                        obj = obj.offsetParent;
                        l += obj.offsetLeft;
                    }
                    return l;
                }

                if (!this.sketchHide) {
                    e.preventDefault();
                    e.stopPropagation();
                    var l = getAbsLeft(obj);

                    e = e || event;
                    this.mouseMenuState("block");
                    this.menuX = e.pageX - l + "px";
                    this.menuY = e.pageY - 20 + "px";
                    return false;
                }
            },
            imgExecuteIdentifyTask(event) {
                var that = this;
                if (that.imageLayers.visible) {
                    that.activeView.hitTest(event).then(function (response) {
                        if (response.results.length > 0) {
                            let gs = response.results[0].graphic;
                            that.imgurl =
                                "http://121.89.201.182:8085" + gs.attributes.url;
                            that.imglxbm = gs.attributes.lxbm;
                            that.imgzh = gs.attributes.zh;
                            that.imgshow = true;
                            console.log(that.imgurl, that.imgzh, that.imglxbm);
                        }
                    });
                }
            },
            //IdentifyTask查询后定义弹出框数据
            executeIdentifyTask(event) {
                // console.log(this.ldLayers);
                //判断是否鼠标左键
                console.log(event, "nb");
                if (event.button != 0) {
                    return false;
                }
                this.clearScreen();
                if (this.mapSelected) {
                    var params = new this.IdentifyParameters();
                    params.tolerance = 10;
                    params.layerOption = "top";
                    params.returnGeometry = true;
                    //定义一个数组存放可见的图层
                    let VisibleLayers = [];
                    //获取所有图层
                    this.ldLayer.allSublayers.items.forEach((v) => {
                        if (subLayerIsVisible(v, this.activeView.scale)) {
                            VisibleLayers.push(v.id);
                        }
                    });
                    params.layerIds = VisibleLayers.sort(this.sortNumbers);
                    params.width = this.activeView.width;
                    params.height = this.activeView.height;
                    params.geometry = event.mapPoint;
                    params.mapExtent = this.activeView.extent;
                    var identifyTask = new this.IdentifyTask(LdUrl);
                    identifyTask.execute(params).then((res) => {
                        console.log(res, "res");
                        if (res.results.length == 0) {
                            // this.$toastr("无查询结果");
                            return;
                        }
                        if (
                            !this.ldLayers.findSublayerById(res.results[0].layerId)
                                .visible
                        ) {
                            return false;
                        }
                        //弹窗点位
                        var popPoint = {
                            geometry: {
                                type: "point",
                                x: event.mapPoint.x,
                                y: event.mapPoint.y,
                                spatialReference: { wkid: 3857 },
                            },
                            symbol: {
                                color: "rgba(255,255,255,0)",
                                size: 0,
                                type: "simple-marker",
                                // style: "square"
                            },
                        };
                        var popGraphic = new this.Graphic(popPoint);
                        popGraphic.pop = {
                            x: event.mapPoint.x,
                            y: event.mapPoint.y,
                        };
                        this.activeView.graphics.add(popGraphic);
                        var feature = res.results[0];
                        // console.log(feature,'feature');
                        this.showFeature(feature);
                    });
                }
            },
            //点击地图 路线 或者 点 高亮
            showFeature(feature) {
                var graphic = feature.feature;
                var geo = graphic.geometry;
                var layerName = feature.layerName;
                var layerId = feature.layerId;
                if (geo.type == "point") {
                    this.activeView.graphics.add(graphic);
                    let title = createTitle(layerName, graphic);
                    this.poptemplate(title);
                    this.animation();
                }
                if (geo.type == "polyline") {
                    if (layerName != "匝道") {
                        layerName = "公路";
                    }
                    var pc = graphic.geometry.paths[0];
                    var start = pc[0]; //起点坐标
                    var end = pc[pc.length - 1]; //止点坐标
                    var center; //中心点坐标
                    var arrowStartIndex, arrowEndIndex;

                    if (pc.length % 2 == 0) {
                        arrowEndIndex = Math.round(pc.length / 2);
                        arrowStartIndex = Math.round(pc.length / 2 - 1);
                        var extent = new this.Extent(
                            pc[arrowStartIndex][0],
                            pc[arrowStartIndex][1],
                            pc[arrowEndIndex][0],
                            pc[arrowEndIndex][1],
                            new this.SpatialReference({ wkid: 3857 })
                        );
                        center = [extent.center.x, extent.center.y];
                    } else {
                        arrowEndIndex = Math.round(pc.length / 2 + 1);
                        arrowStartIndex = Math.round(pc.length / 2 - 1);
                        center = pc[Math.round(pc.length / 2)];
                    }
                    var startPoint = {
                        geometry: {
                            type: "point",
                            x: start[0],
                            y: start[1],
                            spatialReference: { wkid: 3857 },
                        },
                        symbol: {
                            color: [255, 0, 0],
                            size: 10,
                            type: "simple-marker",
                        },
                    };

                    var endPoint = {
                        geometry: {
                            type: "point",
                            x: end[0],
                            y: end[1],
                            spatialReference: { wkid: 3857 },
                        },
                        symbol: {
                            color: [0, 128, 0],
                            size: 10,
                            type: "simple-marker",
                        },
                    };

                    var gStart = new this.Graphic(startPoint);
                    var gEnd = new this.Graphic(endPoint);
                    if (layerName == "公路") {
                        var centerPoint = {
                            geometry: {
                                type: "point",
                                x: center[0],
                                y: center[1],
                                spatialReference: { wkid: 3857 },
                            },
                        };

                        var gCenter = new this.Graphic(centerPoint);
                        var angle = calculateAngle(
                            pc[arrowStartIndex],
                            pc[arrowEndIndex]
                        ); //计算图形符号角度
                        var arrowSymbol = {
                            url: this.img1,
                            height: "40px",
                            width: "40px",
                            type: "picture-marker",
                            angle: angle,
                        };
                        gCenter.symbol = arrowSymbol; //设置图形符号
                        var textSymbol = {
                            type: "text",
                            text: graphic.attributes.Ldxlh || "1",
                            color: [255, 255, 255],
                            font: { size: 8 },
                            xoffset: 0,
                            yoffset: -3,
                        };

                        var gCenterFont = new this.Graphic(centerPoint);
                        gCenterFont.symbol = textSymbol;
                        graphic.symbol = {
                            type: "simple-line",
                            color: "black",
                            width: 4,
                        };
                    }
                    this.activeView.graphics.add(graphic);
                    this.activeView.graphics.add(gStart);
                    this.activeView.graphics.add(gEnd);
                    if (layerName == "公路") {
                        this.activeView.graphics.add(gCenter);
                        this.activeView.graphics.add(gCenterFont);
                    }

                    var title = createTitle(layerName, graphic, layerId);
                    this.poptemplate(title);
                }
            },
            //IdentifyTask查询后定义弹出框数据
            planExecuteIdentifyTask(event) {
                if (
                    !this.planLayer.findSublayerById(0).visible ||
                    !this.planLayer.findSublayerById(1).visible
                ) {
                    return false;
                }
                // console.log('ev');
                // console.log(event);
                //判断是否鼠标左键
                if (event.button != 0) {
                    return false;
                }

                // this.clearScreen();
                if (this.mapSelected) {
                    var params = new this.IdentifyParameters();
                    params.tolerance = 10;
                    params.layerOption = "top";
                    params.returnGeometry = true;
                    //定义一个数组存放可见的图层
                    let VisibleLayers = [];
                    //获取所有图层
                    this.planLayer.allSublayers.items.forEach((v) => {
                        VisibleLayers.push(v.id);
                    });

                    params.layerIds = VisibleLayers.sort(this.sortNumbers);
                    params.width = this.activeView.width;
                    params.height = this.activeView.height;
                    params.geometry = event.mapPoint;
                    params.mapExtent = this.activeView.extent;
                    var identifyTask = new this.IdentifyTask(planUrl);
                    identifyTask.execute(params).then((res) => {
                        if (res.results.length == 0) {
                            this.$toastr("无查询结果");
                            return;
                        }
                        //弹窗点位
                        // var popPoint = {
                        //     geometry: {
                        //         type: "point",
                        //         x: event.mapPoint.x,
                        //         y: event.mapPoint.y,
                        //         spatialReference: { wkid: 3857 },
                        //     },
                        //     symbol: {
                        //         color: "rgba(0,0,0,1)",
                        //         size: 0,
                        //         type: "simple-marker",
                        //         style: "square",
                        //     },
                        // };
                        // var popGraphic = new this.Graphic(popPoint);
                        // popGraphic.pop = {
                        //     x: event.mapPoint.x,
                        //     y: event.mapPoint.y,
                        // };
                        // this.activeView.graphics.add(popGraphic);
                        var feature = res.results[0];

                        // this.roadModel = feature.feature.attributes;
                        //    var xmxh = feature.feature.attributes.ldxlh.replace(/\b(0+)/gi, "");
                        var xmxx = feature.feature.attributes;
                        var xh = feature.feature.attributes.OBJECTID;
                        // console.log(JSON.stringify(feature.feature.attributes));
                        // console.log(JSON.stringify(this.roadModel));
                        // console.log(this.findData);
                        // console.log(feature.feature.attributes);
                        // "三岔口至石岩镇穆棱镇宁安穆棱界至鹤大公路段"
                        // "三岔口至石岩镇穆棱镇宁安穆棱界至鹤大公路段"
                        // if (!this.findData.find((r) =>r.XMMC == this.roadModel.BZ ||r.XMMC == this.roadModel.XMMC)) {
                        //     return false;
                        // }
                        // this.findData.find((r) => r.XH == xh)
                        console.log(xmxx);
                        var find = this.findData.find(
                            (r) =>
                                r.BZ == xmxx.BZ ||
                                r.XMMC == xmxx.XMMC ||
                                r.XMMC == xmxx.BZ
                        );
                        console.log(find);
                        if (find) {
                            this.roadModel = find;
                            this.roadShow = true;
                            this.roadChildShow = true;
                        } else {
                            this.roadShow = false;
                        }

                        this.showPlanFeature(feature);
                    });
                }
            },
            showPlanFeature(feature) {
                console.log(feature)
                var graphic = feature.feature;
                // console.log(graphic);
                // console.log(graphic.geometry.type);
                var geo = graphic.geometry;
                var layerName = feature.layerName;
                var layerId = feature.layerId;
                // var title = createTitle(layerName, graphic, layerId);
                let sym = {
                    type: "simple-fill", // autocasts as new SimpleFillSymbol()
                    color: [0, 0, 0, 0],
                    outline: {
                        color: [240, 29, 195],
                        width: "4",
                    },
                };
                graphic.symbol = sym;
                this.activeView.graphics.add(graphic);

                // if (geo.type == "polyline") {
                //     let sym = {
                //         type: "simple-fill", // autocasts as new SimpleFillSymbol()
                //         color: [0, 0, 0, 0],
                //         outline: {
                //             color: [240, 29, 195],
                //             width: "4",
                //         },
                //     };
                //     graphic.symbol = sym;
                //     this.activeView.graphics.add(graphic);
                //     // this.poptemplate(title);
                // }
                // if (geo.type == "polygon") {
                //     let sym = {
                //         type: "simple-fill", // autocasts as new SimpleFillSymbol()
                //         color: [0, 0, 0],
                //         outline: {
                //             // autocasts as new SimpleLineSymbol()
                //             color: [240, 29, 195],
                //             width: "4",
                //         },
                //     };

                //     graphic.symbol = sym;
                //     this.activeView.graphics.add(graphic);
                //     // this.poptemplate(title);
                // }
            },
            //控制鼠标右键菜单存不存在
            hidemenu() {
                this.mouseMenuState("none");
                this.mapView.graphics.removeAll();
            },
            /**
             * 二三维切换
             */
            switchView() {
                // Clone the viewpoint for the MapView or SceneView
                const viewpoint = this.activeView.viewpoint.clone();
                // Get the view type, either 2d or 3d
                const type = this.activeView.type;
                this.clearMeasurements();
                this.activeView.container = null;
                this.activeView = null;
                // Set the view based on whether it switched to 2D MapView or 3D SceneView
                this.activeView =
                    type.toUpperCase() === "2D" ? this.sceneView : this.mapView;

                if (type.toUpperCase() == "2D") {
                    this.switchLayer(0);
                } else {
                    this.switchLayer(1);
                }
                this.activeView.set({
                    container: "viewDiv",
                    viewpoint: viewpoint,
                });
                this.activeView.ui.add(this.measurement, "bottom-right");
                this.measurement.view = this.activeView;
                const switchButton = document.getElementById("switch-btn");
                switchButton.value = type.toUpperCase();
            },
            setVisiLayers() {
                this.ldLayer.sublayers = this.layers;
            },
            loadingState(state) {
                this.loading = state;
            },
            createPolygonGraphic(vertices) {
                //执行画框
                this.activeView.graphics.removeAll();
                //获取所有顶点
                if (vertices.length < 2) {
                    return;
                }
                var rings = [
                    vertices[0],
                    [vertices[0][0], vertices[1][1]],
                    vertices[1],
                    [vertices[1][0], vertices[0][1]],
                ];
                //清除之前绘制
                this.activeView.graphics.removeAll();

                var rectangle = new this.Polygon({
                    hasZ: false,
                    hasM: false,
                    rings: [rings],
                    spatialReference: this.activeView.spatialReference,
                });

                // 生成绘制的图形
                var graphic = new this.Graphic({
                    geometry: rectangle,
                    symbol: {
                        type: "simple-fill", // autocasts as new SimpleFillSymbol()
                        color: [51, 51, 204, 0.5],
                        style: "short-dot",
                        outline: {
                            // autocasts as new SimpleLineSymbol()
                            color: "white",
                            width: 1,
                        },
                    },
                });

                var polygon = new this.Polygon({
                    rings: rectangle.rings[0],
                    spatialReference: this.activeView.spatialReference,
                });

                var area = this.GeometryEngine.geodesicArea(
                    polygon,
                    "square-kilometers"
                );

                // 将绘制的图形添加到view
                this.activeView.graphics.add(graphic);
            },
            drawActionS() {
                //点击工具开始画框
                var _this = this;
                this.actions = this.draw.create("polygon");

                this.actions.on("vertex-add", function (evt) {
                    _this.createPolygonGraphic(evt.vertices);
                });
                // Fires when the pointer moves over the view
                this.actions.on("cursor-update", function (evt) {
                    _this.createPolygonGraphic(evt.vertices);
                });
                // 按ctrl+c撤销一个点的时候触发
                this.actions.on("vertex-remove", function (evt) {
                    _this.createPolygonGraphic(evt.vertices);
                });
                //双击完成的时候触发
                this.actions.on("draw-complete", function (evt) {
                    _this.createPolygonGraphic(evt.vertices);
                });
            },
            addImgLayers() {},
        },
        mounted() {
            var that = this;
            // setTimeout(() => {

            //     // this.drawActionS();//画框
            // }, 3000);
            /**
             * 整线选取
             */
            window.queryLx = function (val, id) {
                that.loading = true;
                that.clearScreen();
                var queryTask = new that.QueryTask(QueryUrl + id);
                var query = new that.Query();
                query.where = "LXBM  = '" + val + "'";
                query.outSpatialReference = { wkid: 3857 };
                query.returnGeometry = true;
                query.outFields = ["*"];
                queryTask.execute(query).then(that.showResultsLX);
            };
            /**
             * 关闭弹窗
             */
            window.closePop = function () {
                var infos = document.getElementsByClassName("poptemplate");
                if (infos.length > 0) {
                    infos[0].remove();
                }
            };

            /**
             * 查询详情
             */
            window.showForm = function (guid, queryname) {
                console.log(guid, queryname);
            };

            loadCss(apiDomainCss);
            loadModules(
                [
                    "esri/Map",
                    "esri/views/MapView",
                    "esri/views/SceneView",
                    "esri/request",
                    "esri/layers/WebTileLayer",
                    "esri/layers/BaseTileLayer",
                    "esri/layers/MapImageLayer",
                    "esri/layers/TileLayer",
                    "esri/geometry/Point",
                    "esri/Graphic",
                    "esri/tasks/QueryTask",
                    "esri/rest/support/Query",
                    "esri/geometry/SpatialReference",
                    "esri/geometry/Extent",
                    "esri/Color",
                    "esri/tasks/IdentifyTask",
                    "esri/rest/find",
                    "esri/rest/support/FindParameters",
                    "esri/rest/support/IdentifyParameters",
                    "esri/views/2d/draw/Draw",
                    "esri/geometry/Polygon",
                    "esri/core/watchUtils",
                    "esri/identity/IdentityManager",
                    "esri/identity/ServerInfo",
                    "esri/widgets/Legend",
                    "esri/widgets/Measurement",
                    "esri/geometry/support/webMercatorUtils",
                    "esri/geometry/geometryEngine", //画框
                    "esri/symbols/TextSymbol",
                    "esri/layers/GraphicsLayer",
                ],
                {
                    url: apiDomainJs,
                }
            )
                .then(
                    ([
                         Map,
                         MapView,
                         SceneView,
                         request,
                         WebTileLayer,
                         BaseTileLayer,
                         MapImageLayer,
                         TileLayer,
                         Point,
                         Graphic,
                         QueryTask,
                         Query,
                         SpatialReference,
                         Extent,
                         Color,
                         IdentifyTask,
                         find,
                         FindParameters,
                         IdentifyParameters,
                         Draw,
                         Polygon,
                         watchUtils,
                         IdentityManager,
                         ServerInfo,
                         Legend,
                         Measurement,
                         webMercatorUtils,
                         GeometryEngine, //画框
                         TextSymbol,
                         GraphicsLayer,
                     ]) => {
                        this.webMercatorUtils = webMercatorUtils;
                        this.Graphic = Graphic;
                        this.QueryTask = QueryTask;
                        this.Query = Query;
                        this.Point = Point;
                        this.watchUtils = watchUtils;
                        this.IdentifyParameters = IdentifyParameters;
                        this.IdentifyTask = IdentifyTask;
                        this.Extent = Extent;
                        this.SpatialReference = SpatialReference;
                        this.Polygon = Polygon;
                        this.find = find;
                        this.FindParameters = FindParameters;
                        this.GeometryEngine = GeometryEngine; //画框
                        this.TextSymbol = TextSymbol;
                        //背景图层
                        this.bgLayer = new TileLayer({
                            url: BgUrl,
                            visible: true,
                        });
                        //地貌图层
                        this.dmLayer = new TileLayer({
                            url: DiMaoUrl,
                            visible: false,
                        });

                        //影像图层
                        this.yxLayer = new TileLayer({
                            url: YingXiangUrl,
                            visible: false,
                        });

                        //获取Token
                        // identityManager.tokenValidity = 160; //设置token过期时间720分钟
                        ServerInfo.serverString = RestUrl + "/arcgis/rest/services"; //这里配置ArcGIS Server的REST服务地址
                        ServerInfo.tokenServiceUrl =
                            RestUrl + "/arcgis/tokens/generateToken"; //由于GIS Server和Portal联合了，所以使用Portal的token生成地址
                        // serverInfo.shortLivedTokenValidity = 720;//设置token过期时间720分钟
                        var userInfo = { username: "zzUser", password: "gis_1234" }; //这里填写Portal的用户和密码
                        // console.log("serverInfo", serverInfo);
                        var that = this;
                        IdentityManager.generateToken(ServerInfo, userInfo).then(
                            function (data) {
                                that.token = data.token;
                                window.localStorage.gisToken = data.token;
                                //注册Token，注册之后，在Portal里的所有资源，只要该用户由权限访问，就可以直接使用，之前的所有安全服务请求都将会把token值作为参数发送到服务器端
                                IdentityManager.registerToken({
                                    server: RestUrl + "/arcgis/rest/services",
                                    token: data.token,
                                });
                                // console.log(imglists);
                                //加载需要token的图层
                                //公路路段图层
                                that.ldLayer = new MapImageLayer({
                                    url: LdUrl,
                                    title: "公路",
                                    visible: false,
                                    // sublayers: that.layers,
                                });

                                //路况图层
                                that.trafficLayer = new MapImageLayer({
                                    url: trafficUrl,
                                    visible: false,
                                });

                                //规划公路图层
                                // that.planLayer = new MapImageLayer({
                                //     url: planUrl,
                                //     visible: false,
                                // });
                                that.lkLayers = new MapImageLayer({
                                    url: "http://39.100.134.93:6080/arcgis/rest/services/ztg331/MapServer/",
                                    id: "lkLayers",
                                    visible: false,
                                });
                                that.lkLayers = new MapImageLayer({
                                    url: "http://39.100.134.93:6080/arcgis/rest/services/ztg331/MapServer/",
                                    id: "lkLayers",
                                    visible: false,
                                });
                                that.hxLayers = new TileLayer({
                                    url: "http://39.100.134.93:6080/arcgis/rest/services/hongxian/MapServer",
                                    id: "hxLayers",
                                    visible: false,
                                });
                                that.ntLayers = new TileLayer({
                                    url: "http://39.100.134.93:6080/arcgis/rest/services/nongtian/MapServer",
                                    id: "ntLayers",
                                    visible: false,
                                });
                                that.lyLayers = new MapImageLayer({
                                    url: "http://39.100.134.93:6080/arcgis/rest/services/ztlieyang/MapServer/",
                                    id: "lyLayers",
                                    sublayers: [
                                        {
                                            id: 5, //国道列养
                                            visible: false,
                                        },
                                        {
                                            id: 4, //国道未列养
                                            visible: false,
                                        },
                                        {
                                            id: 3, //国道未列养砂石
                                            visible: false,
                                        },
                                        {
                                            id: 2, //省道列养
                                            visible: false,
                                        },
                                        {
                                            id: 1, //省道未列养
                                            visible: false,
                                        },
                                        {
                                            id: 0, //省道未列养砂石
                                            visible: false,
                                        },
                                    ],
                                });
                                that.ldLayers = new MapImageLayer({
                                    url: "http://39.100.134.93:6080/arcgis/rest/services/Gis2013_PROJ_LD/MapServer/",
                                    id: "ldLayers",
                                    sublayers: [
                                        {
                                            id: 34, //村道
                                            visible: false,
                                        },
                                        {
                                            id: 31, //专用公路
                                            visible: false,
                                        },
                                        {
                                            id: 28, //乡道
                                            visible: false,
                                        },
                                        {
                                            id: 25, //县道
                                            visible: false,
                                        },
                                        {
                                            id: 22, //升到高速
                                            visible: true,
                                        },
                                        {
                                            id: 21, //省道
                                            visible: true,
                                        },
                                        {
                                            id: 17, //国道
                                            visible: true,
                                        },
                                        {
                                            id: 12, //涵洞
                                            visible: false,
                                        },
                                        {
                                            id: 10, //隧道
                                            visible: false,
                                        },
                                        {
                                            id: 9, //桥梁
                                            visible: false,
                                        },
                                    ],
                                });
                                // /arcgis/rest/services/plan/MapServer
                                that.planLayer = new MapImageLayer({
                                    url: planUrl,
                                    id: "planLayer",
                                    sublayers: [
                                        {
                                            id: 1,
                                            visible: false,
                                        },
                                        {
                                            id: 0,
                                            visible: false,
                                        },
                                    ],
                                });

                                that.map.addMany([
                                    that.ldLayer,
                                    that.trafficLayer,
                                    //图层组
                                    that.hxLayers,
                                    that.ntLayers,
                                    that.ldLayers,
                                    that.lyLayers,
                                    that.lkLayers,
                                ]);
                                // that.addImgLayers();
                                //图片点位
                                that.imageLayers = new GraphicsLayer({
                                    id: "imageLayers",
                                    visible: false,
                                });
                                var markerSymbol = {
                                    type: "simple-marker",
                                    color: [51, 51, 204, 1],
                                    outline: {
                                        color: [255, 255, 255],
                                        width: 1,
                                    },
                                };
                                var start = 0;
                                imglists.forEach((r) => {
                                    if (start == 0) {
                                        start = r.zh;
                                    } else {
                                        if (r.zh < start + 0.5) {
                                            return;
                                        }
                                    }
                                    var point = {
                                        type: "point",
                                        x: r.x,
                                        y: r.y,
                                        z: 1010,
                                    };
                                    var pointGraphic = new Graphic({
                                        geometry: point, //集合位置
                                        symbol: markerSymbol, //符号
                                        attributes: {
                                            lxbm: "G331",
                                            zh: r.zh,
                                            url: "/images/pqi/" + r.image,
                                        },
                                    });
                                    that.imageLayers.add(pointGraphic);
                                    start = r.zh;
                                });
                                that.map.add(that.imageLayers);
                                //图片点位
                                // that.imageLayers.visible = true;

                                that.map.add(that.planLayer);
                                var postData = {
                                    page: 1,
                                    rows: 1000,
                                    Sort: "XMPX",
                                    Order: "asc",
                                    wheres: "",
                                };
                                that.doFind({}, {}, postData);
                                // console.log(that.$refs.tree,'tree');
                                that.checkIds = [10, 1001, 100101, 100102,1202,1203,1204];
                                // that.$refs.tree.setCheckedKeys(['10', 1001, 100101, 100102]);
                            },
                            function (err) {
                                console.log(err);
                            }
                        );

                        //创建高德地图对象
                        this.TintLayer = createTintLayer(
                            BaseTileLayer,
                            Color,
                            request
                        );

                        //高德影像
                        this.amapImageLayer = new this.TintLayer({
                            urlTemplate: amapImageUrl,
                            tint: new Color("#004FBB"),
                            title: "高德",
                            visible: false,
                        });

                        //高德街区
                        this.amapStreetLayer = new this.TintLayer({
                            urlTemplate: amapStreetUrl,
                            tint: new Color("#004FBB"),
                            title: "高德",
                            visible: false,
                        });

                        //天地图影像
                        this.tdtLayer = new WebTileLayer({
                            urlTemplate: tdtUrl,
                            subDomains: [
                                "t0",
                                "t1",
                                "t2",
                                "t3",
                                "t4",
                                "t5",
                                "t6",
                                "t7",
                            ],
                            copyright: "天地图影像",
                            visible: false,
                        });

                        this.map = new Map({
                            layers: [
                                this.bgLayer,
                                this.dmLayer,
                                this.yxLayer,
                                this.amapImageLayer,
                                this.amapStreetLayer,
                                this.tdtLayer,
                            ],
                        });
                        console.log(document.getElementById('viewDiv'))
                        this.mapView = new MapView({
                            map: this.map,
                            container: "viewDiv",
                            constraints: {
                                rotationEnabled: false, //禁止旋转
                            },
                        });

                        const titleDiv = document.getElementById("titleDiv");
                        this.mapView.ui.add(titleDiv, "top-left");

                        // Create SceneView with similar extent to MapView
                        this.sceneView = new SceneView({
                            map: this.map,
                        });

                        this.activeView = this.mapView;
                        this.measurement = new Measurement();

                        /*矩形*/
                        this.draw = new Draw({
                            view: this.mapView,
                        });

                        // console.log(this.draw);
                        //测量初始化
                        this.activeView.set({
                            container: "viewDiv",
                        });
                        this.activeView.ui.add(this.measurement, "bottom-right");
                        this.measurement.view = this.activeView;

                        //点击空白关闭鼠标右键菜单
                        document
                            .getElementById("viewDiv")
                            .addEventListener("click", () => {
                                this.mouseMenuState("none");
                            });

                        //地图缩放监听
                        this.watchUtils.when(
                            this.activeView,
                            "extent",
                            this.animation
                        );
                        this.watchUtils.when(
                            this.activeView,
                            "extent",
                            this.poptemplate
                        );

                        this.mapView.ui.remove("attribution"); //移除底部ESRI logo
                        this.mapView.ui._removeComponents(["zoom"]); //移除默认ui
                    }
                )
                .catch((err) => {
                    console.error("err", err);
                });
        },
    };
</script>
<style scoped lang="less">
    /deep/ .esri-ui-corner {
        width: 100%;
    }
    /deep/ .esri-ui-inner-container {
        width: 100%;
        left: 0 !important;
        right: auto !important;
        top: 0 !important;
        bottom: auto !important;
    }
    #infoDiv {
        display: none;
        position: absolute;
        bottom: 15px;
        left: 15px;
    }
    #layersDiv input {
        border: none;
        box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 2px;
    }

    #layersDiv {
        position: absolute;
        padding: 10px;
        width: 275px;
        top: 55px;
        right: 10px;
    }
    #sliderValue {
        margin-left: 10px;
        font-weight: bolder;
    }

    #legendDiv {
        width: 260px;
        margin-left: 10px;
        margin-top: 1px;
    }
    #description {
        padding: 10px 0 10px 0;
    }
    #titleDiv {
        width: 100%;
        background: #fff;
        box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.06);
        margin-top: 0px;
        padding: 15px;
        height: 85px;
        overflow-x: auto;
        overflow-y: hidden;
    }

    .panel-side {
        padding: 2px;
        width: 390px;
        height: 85%;
        position: absolute;
        background: white;
        top: 0;
        right: 0;
        margin-top: 50px;
        margin-right: 10px;
        z-index: 999;
    }

    #resultsDiv {
        overflow: auto;
        height: 100%;
    }

    .query_result {
        width: 376px;
        position: absolute;
        top: 85px;
        left: auto;
        width: 430px;
        margin-left: 15px;
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.5s ease;
        background: rgba(255, 255, 255, 0.95);
        box-shadow: 0px 1px 5px 0px rgba(2, 40, 97, 0.15);
        border-radius: 3px;
        flex-wrap: wrap;
        padding: 12px;

        .nav {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 10px;

            .left_nav {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                span {
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: rgba(0, 123, 255, 1);
                    margin-right: 8px;

                    &:first-child {
                        width: 4px;
                        height: 16px;
                        background: rgba(0, 123, 255, 1);
                        border-radius: 2px;
                    }
                }
            }

            .right_nav {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                cursor: pointer;

                i {
                    width: 18px;
                    height: 16px;

                    img {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }
        }

        .main_result {
            width: 100%;
            height: 80vh;
            // display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            overflow-y: scroll;

            .item_result {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 5px;
                // width: 100%;
                border-bottom: 1px solid rgba(0, 0, 0, 0.15);

                .left_item {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    // margin-right: 15px;
                    width: 25px;

                    i {
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        img {
                            width: 14px;
                            height: 16px;
                            cursor: pointer;
                        }
                    }
                }

                .right_item {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-left: 10px;
                    width: 0;

                    i {
                        cursor: pointer;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        img {
                            width: 16px;
                            height: 16px;
                        }
                    }
                }

                .center_item {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    padding-bottom: 5px;
                    width: calc(100% - 50px);

                    > span {
                        font-size: 12px;
                        width: 300px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: rgba(126, 126, 126, 1);
                        line-height: 2em;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;

                        &:first-child {
                            font-size: 14px;
                            font-family: Microsoft YaHei;
                            font-weight: bold;
                            color: rgba(0, 0, 0, 1);
                            cursor: pointer;
                        }
                    }
                }
            }
            .active {
                color: rgb(0, 143, 212);
                span {
                    color: rgb(0, 143, 212) !important;
                }
            }
        }
    }
    .MapLayers {
        position: absolute;
        top: 93px;
        right: 10px;
        box-shadow: 0px 0px 4px 0px rgba(12, 12, 12, 0.15);
        background: rgba(255, 255, 255, 0.8);
        border-radius: 2px;
        display: flex;
        align-items: center;
        padding: 3px;
        box-sizing: border-box;
        height: 36px;

        display: flex;
        justify-content: center;
        align-items: center;
        width: 100px;
        cursor: pointer;
        font-size: 12px;
        font-weight: 400;
        color: #5f6e9e;
        i {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 5px;
        }
    }
    .MapLayers1 {
        color: #409eff;
    }
    .mapLayers.active {
        height: 78vh;
        opacity: 1;
        z-index: 999;
    }
    .mapLayers {
        overflow: auto;
        z-index: -1;
        transition: 0.5s ease;
        width: 260px;
        background: #fff;
        height: 0;
        opacity: 0;
        position: absolute;
        padding: 5px 15px 20px 15px;
        top: 140px;
        right: 10px;
        bottom: 0;
        box-shadow: 0 8px 10px 0px #ddd, 0 16px 24px 0px #ddd, 0 6px 10px 0px #ddd;
        border-radius: 5px;
        .title {
            border-bottom: 1px solid #ddd;
            height: 46px;
            width: 100%;
            z-index: 1500;
            color: #1d9ef0;
            font-weight: 600;
            line-height: 46px;
            line-height: 46px;
            display: inline-block;
            padding: 0 0 0 20px;
            letter-spacing: 0.1em;
            font-size: 16px;
            text-decoration: none !important;
            box-sizing: border-box;
            margin-bottom: 15px;
            position: relative;
            &::before {
                content: "";
                position: absolute;
                left: 18px;
                top: 14px;
                bottom: -2px;
                right: auto;
                height: 17px;
                width: 4px;
                margin-left: -18px;
                border-radius: 25px;
                background-color: #1d9ef0;
            }
        }
    }

    .box123123 {
        transition: 0.5s ease;
        height: 81vh;
        border-radius: 0 4px 4px 4px;
        .leftMenu {
            overflow: hidden;
            display: flex;
            align-items: center;
            // height: 304px;
            background: rgba(0, 143, 212, 0.95);
            // background: #eee;
            // box-shadow: 0px 0px 30px rgba(82, 63, 105, 0.05);
            opacity: 1;
            border-radius: 8px 8px 0px 0px;
            position: absolute;
            left: 0px;
            top: 0;
            .ul {
                padding: 0 9px;
                text-align: center;
                .li {
                    border-bottom: 1px solid #409eff80;
                    color: #fff;
                    // color: #409eff;
                    font-size: 12px;
                    cursor: pointer;
                    padding: 7px 0;
                    .t {
                        margin-top: 5px;
                    }
                    .img1 {
                        display: none;
                    }
                    .img2 {
                        margin: 0 auto;
                        display: block;
                    }
                }
            }
            .ul:last-child {
                .li {
                    border-bottom: 0;
                }
            }
            .active {
                background: #0286c5;
                .li {
                    color: #fff;
                    // .img1 {
                    //     display: none;
                    // }
                    // .img2 {
                    //     margin: 0 auto;
                    //     display: block;
                    // }
                }
            }
        }
    }
    .r105 {
        right: -105%;
    }
    .leftbg {
        padding: 5px;
        line-height: 1;
        font-size: 12px;
        border-radius: 4px;
    }
    .leftbg1 {
        background: rgba(254, 240, 240, 1);
        border: 1px solid #fde2e2;
        color: #f56c6c;
    }
    .leftbg2 {
        background: rgba(233, 249, 250, 1);
        border: 1px solid #d3f4f4;
        color: #24c6c8;
    }

    .leftbg3 {
        background: rgba(253, 246, 236, 1);
        border: 1px solid #faecd8;
        color: #e6a23c;
    }
    /deep/.el-table__body-wrapper,
    .el-table__header-wrapper {
        overflow-y: auto;
    }
    /deep/.el-table {
        .el-table__cell {
            padding: 5px 0px;
        }
    }
    .el-tree {
        left: -9px;
    }
    .imgDiv {
        position: absolute;
        top: 95px;
        left: 15px;
        z-index: 1001;
        width: 400px;
        padding: 10px;
        border-radius: 5px;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 1px 5px 0px rgba(2, 40, 97, 0.15);
        .right_nav {
            position: absolute;
            right: 10px;
            top: 5px;
            cursor: pointer;
        }
        .title {
            margin-bottom: 10px;
            align-items: baseline;
            display: flex;
            font-size: 12px;
            color: #999;
            font-style: normal;
            .lc {
                color: #012654;
                font-size: 20px;
                margin: 0 2px;
            }
        }
        .img {
            width: 100%;
        }
    }
    /deep/.el-dialog{
        margin-top: 15vh !important;
    }
    .el-tabs__content{
        overflow: scroll;
    }
    /deep/.el-dialog__body{
        overflow: scroll;
    }
</style>
