<template>
  <div>
    <el-descriptions :column="1">
      <el-descriptions-item label="项目名称">
        {{ roadModel.XMMC!=' '?roadModel.XMMC:roadModel.BZ }}
      </el-descriptions-item>
      <el-descriptions-item label="路线编号">
        {{
          roadModel.LXBH != null && roadModel.LXBH != "" ? roadModel.LXBH : (roadModel.LXBM||'无')
        }}
      </el-descriptions-item>
      <el-descriptions-item label="项目性质">
        {{
          roadModel.XMXZ != null && roadModel.XMXZ != "" ? roadModel.XMXZ : "无"
        }}
      </el-descriptions-item>
      <el-descriptions-item label="建设性质">
        {{
          roadModel.JSXZ != null && roadModel.JSXZ != "" ? roadModel.JSXZ : "无"
        }}
      </el-descriptions-item>
      <el-descriptions-item label="项目类型">
        {{
          roadModel.XMLX != null && roadModel.XMLX != "" ? roadModel.XMLX : "无"
        }}
      </el-descriptions-item>
      <el-descriptions-item label="建设类别">
        {{ roadModel.GLLX }}
      </el-descriptions-item>
      <el-descriptions-item label="开工年">
        {{
          roadModel.KGN != null && roadModel.KGN != "" ? roadModel.KGN : "无"
        }}
      </el-descriptions-item>
      <el-descriptions-item label="完工年">
        {{
          roadModel.WGN != null && roadModel.WGN != "" ? roadModel.WGN : "无"
        }}
      </el-descriptions-item>
      <el-descriptions-item v-if="roadModel.XZDJYJ != null" label="现状等级"
        ><b> {{ roadModel.XZDJYJ }} </b>
        公里（一级）
      </el-descriptions-item>
      <el-descriptions-item v-if="roadModel.XZDJEJ != null" label="现状等级"
        ><b> {{ roadModel.XZDJEJ }} </b>
        公里（二级）
      </el-descriptions-item>
      <el-descriptions-item v-if="roadModel.XZDJSJ != null" label="现状等级"
        ><b> {{ roadModel.XZDJSJ }} </b>
        公里（三级）
      </el-descriptions-item>
      <el-descriptions-item v-if="roadModel.XZDJSI != null" label="现状等级"
        ><b> {{ roadModel.XZDJSI }} </b>
        公里（四级）
      </el-descriptions-item>
      <el-descriptions-item v-if="roadModel.XZDJDW != null" label="现状等级"
        ><b> {{ roadModel.XZDJDW }} </b>
        公里（等外）
      </el-descriptions-item>
      <el-descriptions-item v-if="roadModel.JSGMGS != null" label="建设规模"
        ><b> {{ roadModel.JSGMGS }} </b>
        公里（高速）
      </el-descriptions-item>
      <el-descriptions-item v-if="roadModel.JSGMYJ != null" label="建设规模"
        ><b> {{ roadModel.JSGMYJ }} </b>
        公里（一级）
      </el-descriptions-item>
      <el-descriptions-item v-if="roadModel.JSGMEJ != null" label="建设规模"
        ><b> {{ roadModel.JSGMEJ }} </b>
        公里（二级）
      </el-descriptions-item>
      <el-descriptions-item v-if="roadModel.JSGMSJ != null" label="建设规模"
        ><b> {{ roadModel.JSGMSJ }} </b>
        公里（三级）
      </el-descriptions-item>
      <el-descriptions-item v-if="roadModel.JSGMQS != null" label="建设规模"
        ><b> {{ roadModel.JSGMQS }}</b>
        延米（桥隧）
      </el-descriptions-item>
      <el-descriptions-item label="建设地址">
        {{ roadModel.SSDS }} {{ roadModel.SSX }}
      </el-descriptions-item>
      <el-descriptions-item
        label="总投资"
        v-if="roadModel.ZTZ != null && roadModel.ZTZ != 0"
        ><b> {{ roadModel.ZTZ }}</b> 亿元
      </el-descriptions-item>
      <el-descriptions-item
        label="中央车购税"
        v-if="roadModel.ZYCGS != null && roadModel.ZYCGS != 0"
        ><b> {{ roadModel.ZYCGS }}</b> 亿元
      </el-descriptions-item>
      <el-descriptions-item
        label="“十三五”投资"
        v-if="roadModel.SAWZTJ != null && roadModel.SAWZTJ != 0"
        ><b> {{ roadModel.SAWZTJ }}</b> 亿元
      </el-descriptions-item>
      <el-descriptions-item
        label="“十三五”中央车购税"
        v-if="roadModel.SAWZYCGS != null && roadModel.SAWZYCGS != 0"
        ><b> {{ roadModel.SAWZYCGS }}</b> 亿元
      </el-descriptions-item>
      <el-descriptions-item
        label="“十四五”投资"
        v-if="roadModel.SSWZTZ != null && roadModel.SSWZTZ != 0"
        ><b> {{ roadModel.SSWZTZ }}</b> 亿元
      </el-descriptions-item>
      <el-descriptions-item
        label="“十四五”中央车购税"
        v-if="roadModel.SSWZYCGS != null && roadModel.SSWZYCGS != 0"
        ><b> {{ roadModel.SSWZYCGS }}</b> 亿元
      </el-descriptions-item>
      <el-descriptions-item
        label="“十四五”省级资金"
        v-if="roadModel.SSWSJZJ != null && roadModel.SSWSJZJ != 0"
        ><b> {{ roadModel.SSWSJZJ }}</b> 亿元
      </el-descriptions-item>
      <el-descriptions-item
        label="“十四五”市县征拆投资"
        v-if="roadModel.SSWSXZCTZ != null && roadModel.SSWSXZCTZ != 0"
        ><b> {{ roadModel.SSWSXZCTZ }}</b> 亿元
      </el-descriptions-item>
      <el-descriptions-item
        label="“十五五”投资"
        v-if="roadModel.SWWZTZ != null && roadModel.SWWZTZ != 0"
        ><b> {{ roadModel.SWWZTZ }}</b> 亿元
      </el-descriptions-item>
      <el-descriptions-item
        label="“十五五”中央车购税"
        v-if="roadModel.SWWZYCGS != null && roadModel.SWWZYCGS != 0"
        ><b> {{ roadModel.SWWZYCGS }}</b> 亿元
      </el-descriptions-item>
      <el-descriptions-item label="是否地市行文">
        {{
          roadModel.SFYDSXW != null && roadModel.SFYDSXW != ""
            ? roadModel.SFYDSXW
            : "无"
        }}
      </el-descriptions-item>
      <el-descriptions-item label="项目来源">
        {{
          roadModel.XMLY != null && roadModel.XMLY != "" ? roadModel.XMLY : "无"
        }}
      </el-descriptions-item>
      <el-descriptions-item label="前期工作进展">
        {{ roadModel.QQGZJZ }}
      </el-descriptions-item>
      <el-descriptions-item label="备注">
        {{ roadModel.BZ != null && roadModel.BZ != "" ? roadModel.BZ : "无" }}
      </el-descriptions-item>
    </el-descriptions>
  </div>
</template>
<script>
export default {
  props: ["roadModel"],
  mounted(){

  }
};
</script>
